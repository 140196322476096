@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;400;600;700&display=swap');

#root {
    font-family: 'IBM Plex Sans Thai', sans-serif;
}

body {
    font-family: 'IBM Plex Sans Thai', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #E5E5EA;
}

.FormTextField {
    border-radius: 8px;
    border: 1px solid var(--basic-ui-colors-gray-5, #D1D1D6);
    background: var(--white, #FFF);
}

.listItem {
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.darkButton {
    display: flex;
    height: 48px;
    width: 100%;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #043673;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
}

.tags-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.tags {
    display: flex;
    gap: 8px;
    /* Adjust the gap as needed */
}

.tags-large .tag {
    padding: 10px 20px;
    /* Larger padding for tags when there are tags */
}

.tag {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-right: 8px;
    /* Adjust the margin as needed */
}

.tag-delete {
    margin-left: 5px;
    cursor: pointer;
}

.darkerBox {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(242, 242, 247, 0.50);
}

.brighterBox {
    width : 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: rgb(255, 255, 255);
}

.header {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: #FFF;
}

.box {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
}